import "./app.scss";
import Startpage from "./pages/startpage/Startpage";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Casino from "./pages/casino/Casino";
import Trivia from "./pages/trivia/Trivia";
import Wordle from "./pages/wordle/Wordle";
import ER404 from "./pages/ER404/ER404";

import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";

import { useContext } from "react";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Startpage />} />
        <Route path="/Abiflix" element={<Home />} />
        <Route path="/Casino" element={<Casino />} />
        <Route path="/Trivia" element={<Trivia />} />
        <Route path="/Wordle" element={<Wordle />} />
        <Route path="/About" element={<About />} />
        <Route path="*" element={<ER404 />} />
      </Routes>
    </Router>
  );
};

export default App;