import tn from '../../../images/test.PNG'
import video from '../../../videos/Recoumberance.mp4'

export const Config = { 
    title: "Er ist wieder da",

    genre: "Action",

    trailer: video,

    thumbnail: tn,

    desc: "Der ehemalige Schulleiter Andreas Jungnitz erwacht Jahrzehnte nach seiner Rente in Hameln. Er ist verwirrt und versucht zu verstehen, was passiert ist. Die Welt, wie er sie vorfindet ist geprägt von Krieg, Unruhe und Unterkursen. Addi möchte sein Ziel, die absolute Schulleitung an sich zu reißen, weiterverfolgen, doch die Menschen, die er trifft, halten ihn für einen witzigen Imitator.",

    pg: "12",

    length: "1Stunde 46min",

    year: "2015"
}
export default Config
