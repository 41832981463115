import useSound from 'use-sound';
import { Link } from "react-router-dom";
import { useState } from 'react'
import './navbar.scss'
import logo from "../../images/Abiflix.png"
import notif from "../../images/Glocke.png"
import search from "../../images/Suche.png"
import dd from "../../images/DropDown.png"
import hoversound from "../../sounds/hoversound.wav"

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false)

  window.onscroll = () =>{
      setIsScrolled(window.pageYOffset === 0 ? false : true)
      return () => window.onscroll = null
  }
  const [playActive] = useSound( hoversound , {volume:0.1});

  return (
    <div className={isScrolled ? 'navbar scrolled' : 'navbar'}>
      <div className='container'>
          <div className='left'>
            <div className="opt" onMouseEnter={() => playActive()}><Link to="/Abiflix"><img src={logo} alt="" /></Link></div>
            <div className="opt" onMouseEnter={() => playActive()}><Link style={{ textDecoration: 'none' }} to="/"><span>Home</span></Link></div>
            <div className="opt" onMouseEnter={() => playActive()}><Link style={{ textDecoration: 'none' }} to="/Casino"><span>Casino</span></Link></div>
            <div className="opt" onMouseEnter={() => playActive()}><Link style={{ textDecoration: 'none' }} to="/Trivia"><span>Trivia</span></Link></div>
            <div className="opt" onMouseEnter={() => playActive()}><Link style={{ textDecoration: 'none' }} to="/Wordle"><span>Wordle</span></Link></div>
            <div className="opt" onMouseEnter={() => playActive()}><Link style={{ textDecoration: 'none' }} to="/About"><span>About</span></Link></div>
          </div>
          <div className='right'>
              <img src={search} onMouseEnter={() => playActive()} alt="" /> 
              <a onMouseEnter={() => playActive()} href="https://www.youtube.com/watch?v=dQw4w9WgXcQ" ><span>Kids</span></a>
              <div className='Glocke' onMouseEnter={() => playActive()}><img src={notif} alt="" /></div>
              <img onMouseEnter={() => playActive()} src="https://t3.ftcdn.net/jpg/03/46/83/96/360_F_346839683_6nAPzbhpSkIpb8pmAwufkC7c5eD7wYws.jpg" alt="" /> 
              <div className="profile">
                <img src={dd} onMouseEnter={() => playActive()} alt="" /> 
                <div className="options">
                    <span onMouseEnter={() => playActive()}>Settings</span>
                    <span onMouseEnter={() => playActive()}>Logout</span>
                </div>
              </div>
          </div>
      </div>
    </div>
  )
}

export default Navbar
