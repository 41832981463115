import * as React from 'react'
import useMouse from '@react-hook/mouse-position'
import testImg from '../../images/testimg.png'
import './parallaxImg.scss'

export default function ParallaxImg() {
    const imgRef = React.useRef(null)
    const contRef = React.useRef(null)

    const mouse = useMouse(contRef, {
        enterDelay: 100,
        leaveDelay: 100,
    })

    const maxWidth = window.innerWidth
    const maxHeight = window.innerHeight

    const handleMove = () => {
        imgRef.current.style.transform = `intial`
        imgRef.current.style.transform = `translate(${mouse.x, mouse.y})`
    }

    return (
        <div ref={contRef} className='outerContainer' onClick={() => handleMove()}>
            <img ref={imgRef} src={testImg} alt="" />
            x: {mouse.x}
            y: {mouse.y}
        </div>
    )
}
