import tn from '../../../images/test.PNG'
import video from '../../../videos/Recoumberance.mp4'

export const Config = { 
    title: "Learn to Survive",

    genre: "Dokumentation",

    trailer: video,

    thumbnail: tn,

    desc: "Schüler, Lehrer und Betreuer erleben das Leben auf der Überholspur während der Sek2. Die Dokumentation gewährt dem Zuschauer exklusive Einblicke über das größte Schulkapitel der Welt und die Menschen dahinter.",

    pg: "0",

    length: "4 Staffeln",

    year: "2022"
}
export default Config
