import { useState } from 'react'
import useSound from 'use-sound';
import './listItem.scss'
import play from "../../images/PlayButtonWeiß.png"
import info from "../../images/Info.png"
import tu from "../../images/Daumen.png"
import td from "../../images/DaumenDown.png"
import hoversound from "../../sounds/hoversound.wav"

export default function ListItem({ index, thumbnail, genre, desc, pg, length, year, trailer, title }) {
  const [isHovered, setIsHovered] = useState(false)
  const [playActive] = useSound( hoversound , {volume:0.1});

  return (
    <div className='listItem'
      style={{ left: isHovered && index * 225 - 50 + index * 2.5 }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)} >
      <img className='Thumbnail' src={thumbnail} alt="" />
      {isHovered && (
        <>
          <video src={trailer} autoPlay={true} loop />
          <div className="itemInfo">
            <div className="icons">
              <img onMouseEnter={() => playActive()} src={play} alt="" />
              <img onMouseEnter={() => playActive()} src={info} alt="" />
              <img onMouseEnter={() => playActive()} src={tu} alt="" />
              <img onMouseEnter={() => playActive()} src={td} alt="" />
            </div>
            <div className="itemInfoTop">
              <span>{length}</span>
              <span className='limit'>{pg}</span>
              <span>{year}</span>
            </div>
            <div className="title">{title}</div>
            <div className="desc">
              {desc}
            </div>
            <div className="genre">
              {genre}
            </div>
          </div>
        </>
      )}
    </div>
  )
}
