import tn from '../../../images/test.PNG'
import video from '../../../videos/Recoumberance.mp4'

export const Config = { 
    title: "How to get good grades (fast)",

    genre: "Action",

    trailer: video,

    thumbnail: tn,

    desc: "Aus seinem Zimmer heraus gründet der Schüler Elias mit seinem besten Freund Lenny Europas größten Online-Spickerversand, um seine große Liebe zurückzugewinnen. Der Online-Verkauf ist ein rießiger Erfolg, was jedoch so einige Probleme mit sich bringt.",

    pg: "16",

    length: "18 Episoden",

    year: "2019"
}
export default Config
