import tn from '../../../images/test.PNG'
import video from '../../../videos/Recoumberance.mp4'

export const Config = { 
    title: "Schiller: Civil War",

    genre: "Action",

    trailer: video,

    thumbnail: tn,

    desc: "Nachdem ein Einsatz einer Lehrer-Nachwuchstruppe eine Katastrophe ausgelöst hat, bei der Schulkinder ums Abi gekommen sind, führt die Schulleitung das Referendariat ein. Demzufolge müssen sich neue Lehrer registrieren und ihre Kräfte in den Dienst des Staates stellen. Teil dessen ist auch die Preisgabe ihrer Identität. Während Herr Kräft keinen Hehl aus seinem Lehrerdasein macht, sieht Maik Satlau dies anders. Es kommt zum Bruch der Referendare und zum Bürgerkrieg.",

    pg: "16",

    length: "2hour 28min",

    year: "2016"
}
export default Config
