import img from '../../images/testimg.png'
import './about.scss'
import ParallaxImg from '../../components/parallaxImg/ParallaxImg'

export default function About() {
  return (
    <div>
      <ParallaxImg/>
    </div>
  )
}
