import tn from '../../../images/test.PNG'
import video from '../../../videos/Recoumberance.mp4'

export const Config = { 
    title: "Michael Wickahi",

    genre: "Action",

    trailer: video,

    thumbnail: tn,

    desc: "Der ehemalige Auftragslehrer Michael Wickahi lebt nach dem Tod seiner Frau zurückgezogen und trauernd im Ghetto Hamelns. Der Hund, den seine Frau ihm geschenkt hat, ist das einzige, woran er noch hängt. Eines Nachts brechen drei Chemiker bei ihm ein, geben seinem Hund möglicherweise verstrahlten Tee und zerstören somit alles, was Michael noch wichtig ist. Daraufhin kehrt der unverwüstbare Auftragslehrer aus seinem Ruhestand zurück und startet einen gnadenlosen Rachefeldzug, der auch vor der Sek2 nicht Halt macht.",

    pg: "18",

    length: "1hour 42min",

    year: "2015"
}
export default Config
