import tn from '../../../images/test.PNG'
import video from '../../../videos/Recoumberance.mp4'

export const Config = { 
    title: "Das Notengambit",

    genre: "Drama",

    trailer: video,

    thumbnail: tn,

    desc: "Die junge Schaffhauser wächst in den 1950er Jahren in einem Waisenhaus in Welliehausen auf. Schon bald entdeckt sie ihr Talent zum Abschreiben und versucht, die von Männern dominierte Schulwelt zu erobern. Jedoch kämpft sie mit einem Suchtproblem.",

    pg: "12",

    length: "7 Episoden",

    year: "2020"
}
export default Config
