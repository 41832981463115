import './featured.scss'
import useSound from 'use-sound';
import play from "../../images/PlayButton.png"
import info from "../../images/Info.png"
import banner from "../../images/Casadepapelwordmark.png"
import hoversound from "../../sounds/hoversound.wav"

export default function Featured() {
  const [playActive] = useSound( hoversound , {volume:0.1});
  
  return (
    <div className='featured'>
        
        <img src="https://www.hanisauland.de/sites/default/files/styles/article_1180/public/1536514-161311-147584.jpg.jpeg" alt="" />

        <div className="fadeout"></div>
        
        <div className="info">
            
            <img src={banner} alt="" />
            
            <span className='desc'>
              According to all known laws
              of aviation,
              there is no way a bee
              should be able to fly.
              Its wings are too small to get
              its fat little body off the ground.
              The bee, of course, flies anyway
              because bees don't care
              what humans think is impossible.
              According to all known laws
              of aviation,
              there is no way a bee
              should be able to fly.
              Its wings are too small to get
              its fat little body off the ground.
              The bee, of course, flies anyway
              because bees don't care
              what humans think is impossible.
              </span>
            
            <div className="buttons">
                
                <button className="play" onMouseEnter={() => playActive()}>
                    <img src={play} alt="" />
                    <span>Abspielen</span>
                </button> 
                
                <button className="more" onMouseEnter={() => playActive()}>
                    <img src={info} alt="" />
                    <span>Weitere_Infos</span>
                </button>

            </div>
        </div>
    </div>
  )
}
