import tn from '../../../images/test.PNG'
import video from '../../../videos/Recoumberance.mp4'

export const Config = { 
    title: "School Break",

    genre: "Action",

    trailer: video,

    thumbnail: tn,

    desc: "Der Architekt cihan Emre befindet sich in einer verzweifelten Situation, denn sein Bruder sitzt beim Nachsitzen im Schiller. Obwohl alle Beweise dagegen sprechen, glaubt Cihan an seine Unschuld. Um ihn zu retten, schmiedet er daher einen äußerst riskanten Plan.",

    pg: "18",

    length: "5 Staffeln",

    year: "2005"
}
export default Config
