import './list.scss'
import ListItem from '../listItem/ListItem.jsx'
import arrleft from'../../images/arrow-88-xxl.png'
import arrright from'../../images/arrow-24-xxl.png'
import {Config as gggf} from '../../lists/top10/GetGoodGradesfast/Config.jsx'
import {Config as bb} from '../../lists/top10/BreakingBad/Config.jsx'
import {Config as ng} from '../../lists/top10/DasNotengambit/Config.jsx'
import {Config as dts} from '../../lists/top10/DriveToSurvive/Config.jsx'
import {Config as eiwd} from '../../lists/top10/ErIstWiederDa/Config.jsx'
import {Config as pb} from '../../lists/top10/PrisonBreak/Config.jsx'
import {Config as scw} from '../../lists/top10/SchillerCivilWar/Config.jsx'
import {Config as sed} from '../../lists/top10/SexEducation/Config.jsx'
import {Config as sw} from '../../lists/top10/SpiderWulze/Config.jsx'
import {Config as jw} from '../../lists/top10/Wickahi/Config.jsx'
import { useRef, useState } from 'react'

export default function List() {
    const [slideNumber, setSlideNumber] = useState(0)
    const [isMoved, setIsMoved] = useState(false)
    const [slideNumber2, setSlideNumber2] = useState(0)
    const [isMoved2, setIsMoved2] = useState(false)

    const listRef = useRef()
    const listRef2 = useRef()

    const handleClick = (direction) =>{
        if(direction === 'left' && slideNumber > 0){
            setSlideNumber(slideNumber - 1)
            listRef.current.style.transform = `translateX(${-233 * (slideNumber - 1)}px)`
            if(slideNumber === 1){setIsMoved(false)}
        }
        if(direction === 'right' && slideNumber < 5){
            if(slideNumber === 0){setIsMoved(true)}
            setSlideNumber(slideNumber + 1)
            listRef.current.style.transform = `translateX(${-233 * (slideNumber + 1)}px)`
        }
    }

    const handleClick2 = (direction) =>{
        if(direction === 'left' && slideNumber2 > 0){
            setSlideNumber2(slideNumber2 - 1)
            listRef2.current.style.transform = `translateX(${-233 * (slideNumber2 - 1)}px)`
            if(slideNumber2 === 1){setIsMoved(false)}
        }
        if(direction === 'right' && slideNumber2 < 5){
            if(slideNumber2 === 0){setIsMoved2(true)}
            setSlideNumber2(slideNumber2 + 1)
            listRef2.current.style.transform = `translateX(${-233 * (slideNumber2 + 1)}px)`
        }
    }

  return (
    <>
    <div className='list'>
        <span className="listTitle">Top 10 in Deutschland</span>
        <div className="wrapper">
            <div className="sliderArrow left" onClick={()=>handleClick('left')} style={{display: !isMoved && 'none'}}>
                <img src={arrleft} alt="" />
            </div>
          
            <div className="container" ref={listRef}>
                <ListItem index={0} thumbnail={gggf.thumbnail} genre={gggf.genre} length={gggf.length} pg={gggf.pg} trailer={gggf.trailer} desc={gggf.desc} year={gggf.year} title={gggf.title}/>
                <ListItem index={1} thumbnail={bb.thumbnail} genre={bb.genre} length={bb.length} pg={bb.pg} trailer={bb.trailer} desc={bb.desc} year={bb.year} title={bb.title}/>
                <ListItem index={2} thumbnail={ng.thumbnail} genre={ng.genre} length={ng.length} pg={ng.pg} trailer={ng.trailer} desc={ng.desc} year={ng.year} title={ng.title}/>
                <ListItem index={3} thumbnail={dts.thumbnail} genre={dts.genre} length={dts.length} pg={dts.pg} trailer={dts.trailer} desc={dts.desc} year={dts.year} title={dts.title}/>
                <ListItem index={4} thumbnail={eiwd.thumbnail} genre={eiwd.genre} length={eiwd.length} pg={eiwd.pg} trailer={eiwd.trailer} desc={eiwd.desc} year={eiwd.year} title={eiwd.title}/>
                <ListItem index={5} thumbnail={pb.thumbnail} genre={pb.genre} length={pb.length} pg={pb.pg} trailer={pb.trailer} desc={pb.desc} year={pb.year} title={pb.title}/>
                <ListItem index={6} thumbnail={scw.thumbnail} genre={scw.genre} length={scw.length} pg={scw.pg} trailer={scw.trailer} desc={scw.desc} year={scw.year} title={scw.title}/>
                <ListItem index={7} thumbnail={sed.thumbnail} genre={sed.genre} length={sed.length} pg={sed.pg} trailer={sed.trailer} desc={sed.desc} year={sed.year} title={sed.title}/>
                <ListItem index={8} thumbnail={sw.thumbnail} genre={sw.genre} length={sw.length} pg={sw.pg} trailer={sw.trailer} desc={sw.desc} year={sw.year} title={sw.title}/>
                <ListItem index={9} thumbnail={jw.thumbnail} genre={jw.genre} length={jw.length} pg={jw.pg} trailer={jw.trailer} desc={jw.desc} year={jw.year} title={jw.title}/>
            </div>
            <div className="sliderArrow right" onClick={()=>handleClick('right')}>
                <img src={arrright} alt="" />
            </div>
        </div>
    </div>
    <div className='list'>
        <span className="listTitle">Einblicke in die Mottowoche</span>
        <div className="wrapper">
            <div className="sliderArrow left" onClick={()=>handleClick2('left')} style={{display: !isMoved2 && 'none'}}>
                <img src={arrleft} alt="" />
            </div>
          
            <div className="container" ref={listRef2}>
                <ListItem index={0}/>
                <ListItem index={1}/>
                <ListItem index={2}/>
                <ListItem index={3}/>
                <ListItem index={4}/>
                <ListItem index={5}/>
                <ListItem index={6}/>
                <ListItem index={7}/>
                <ListItem index={8}/>
                <ListItem index={9}/>
            </div>
            <div className="sliderArrow right" onClick={()=>handleClick2('right')}>
                <img src={arrright} alt="" />
            </div>
        </div>
    </div>
    </>
  )
}
