import tn from '../../../images/test.PNG'
import video from '../../../videos/Recoumberance.mp4'

export const Config = { 
    title: "Sex Education",

    genre: "Action",

    trailer: video,

    thumbnail: tn,

    desc: "Die 16-jährige Annabel ist die Tochter einer bekannten Sexualtherapeutin, hat selbst aber noch nicht viele Erfahrungen sammeln können. Zusammen mit ihrer Biolehrerin Frau Thiemeier beginnt sie, in der Schule Sexualtherapiestunden anzubieten.",

    pg: "16",

    length: "3 Staffeln",

    year: "2019"
}
export default Config
