import tn from '../../../images/Casadepapelwordmark.png'
import video from '../../../videos/Recoumberance.mp4'

export const Config = { 
    title: "Breaking Bad",

    genre: "Action",

    trailer: video,

    thumbnail: tn,

    desc: "Breaking Bad ist eine US-amerikanische Krimi-Drama-Fernsehserie von Vince Gilligan. Sie zeigt die Wandlung einer an Lungenkrebs erkrankten biederen Chemielehrerin zu einer rücksichtslosen Kriminellen.",

    pg: "18",

    length: "8 Staffeln",

    year: "2008"
}
export default Config
