import tn from '../../../images/test.PNG'
import video from '../../../videos/Recoumberance.mp4'

export const Config = { 
    title: "Spider Wulze: Guten Spritz!",

    genre: "Action",

    trailer: video,

    thumbnail: tn,

    desc: "Yannick Wulze kann sich nicht länger hinter seinem Alter Ego Spider-Man verstecken. Silke Jakobs hat dafür gesorgt, dass die freundliche Spinne aus der Nachbarschaft als Yannick Wulze bekannt wird und er zudem für verheerende Abi 2021 verantwortlich gemacht wird. Damit die Welt diesen Vorfall wieder vergisst, bittet Yannick den Zauberer Nils Thiemann um Hilfe. Ein neues Multiversum eröffnet sich, in dem böse Mächte Yannicks Plan zunichtemachen.",

    pg: "16",

    length: "2Stunden 28min",

    year: "2021"
}
export default Config
